<template>
  <div class="box">
    <div class="search">
      <!-- <div class="search-top">
              <div v-for="(item,i) in toplist" :key="i">{{item.name}}（{{item.value}}）</div>
          </div> -->
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户手机号：" prop="phone">
              <el-input v-model="queryInfo.condition.phone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="优惠券名称：" prop="couponsName">
              <el-input v-model="queryInfo.condition.couponsName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户昵称：" prop="memberNikeName">
              <el-input v-model="queryInfo.condition.memberNikeName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="8">
            <el-form-item label="使用时间：" prop="industryType">
              <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <div class="tab">
      <div class="add">
        <el-button type="primary">导出</el-button>
        <!-- <el-button
                type="primary"
                @click="send"
               
                >发券</el-button
              > -->
      </div>
      <el-table :data="tableData" style="width: 100%" show-summary height="calc(100vh - 380px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">

        <el-table-column prop="memberNikeName" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="用户手机号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="couponsName" label="优惠券名称" align="center">

        </el-table-column>
        <el-table-column prop="useIllustrate" label="优惠券内容" align="center">

        </el-table-column>
        <el-table-column prop="creatTime" label="领取时间" width="200" align="center">

        </el-table-column>
        <el-table-column prop=" useTime" label="使用时间" width="150" align="center">

        </el-table-column>



      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="发放优惠券" :visible.sync="showDialog" width="500px" height="600px">
      <div class="dia">
        <el-form label-width="120px" label-height="60px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择用户：" prop="contactName">
                <el-input v-model="from.orderSq" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="选择优惠券：" prop="contactName">
                <el-input v-model="from.categoryName" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>

          </el-row>

        </el-form>

        <div class="diafour">
          <el-button type="danger" @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="save">保存 </el-button>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
export default {
  data() {
    return {
      showDialogtwo: false,
      activeName: 'second',
      drawer: false,
      toplist: [{
        name: '全部',
        value: 33
      }, {
        name: '待审核',
        value: 33
      }, {
        name: '已通过',
        value: 33
      }, {
        name: '已拒绝',
        value: 33
      }],
      tableData: [],
      queryInfo: {
        condition: {
          type: 1,
          isUse: 1
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      total: 0,
      showDialog: false,
      id: '',
      value1: 0,
      showDialog: false,
      from: {},
      valuechange: ''

    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    change() {
      this.queryInfo.condition.startTime = this.newDate(this.valuechange[0])
      this.queryInfo.condition.form.endTime = this.newDate(this.valuechange[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    added() {
      this.$router.push({ path: '/coupondetail' })
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/coupons/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    search() {
      this.queryInfo.currPage = 1;
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        type: 1,
        isUse: 1
      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    send() {
      this.showDialog = true
    },
          // 修改页数大小
          handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },




  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;

    .search-top {
      width: 1540px;
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;

      div {
        width: 150px;
        text-align: center;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .dialist {
      display: flex;
      flex-wrap: wrap;

      .dia-li {
        width: 300px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .name {
          font-weight: 700;
          margin-right: 20px;

        }

        img {
          width: 48px;
          height: 47px;
        }
      }
    }

    .diaimg {
      display: flex;
      flex-wrap: wrap;

      .imgbox {
        width: 300px;
        margin-bottom: 15px;

        .name {
          font-weight: 700;

        }

        .imgli {
          display: flex;
          margin-top: 15px;

          img {
            width: 92px;
            height: 65px;
            margin-right: 10px;
          }
        }
      }
    }

    .sh {
      display: flex;
      margin-top: 40px;

      textarea {
        width: 679px;
        height: 60px;
        margin-left: 10px;
      }

    }

    .but {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 300px;
    }
  }
}
</style>
